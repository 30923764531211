import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCaRKDiTNH6K2EbTPUfoaIL7nW8p_MAe10',
  authDomain: 'xsmbac-a1ec4.firebaseapp.com',
  projectId: 'xsmbac-a1ec4',
  storageBucket: 'xsmbac-a1ec4.appspot.com',
  messagingSenderId: '621015495226',
  appId: '1:621015495226:web:5b5fc699baa68338033b03',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { auth, firestore, storage };
